import styled from "styled-components";
import { Tabs, Text } from "../../Components";
import { STYLES } from "../../Constants";
import { COLORS } from "../../Constants/styles.constants";

export const PageTitle = styled(Text)`
  margin-top: 16px;
  height: 38px;
`;

export const ApplicationTabs = styled(Tabs)`
  padding: 16px;
  padding-top: 0;
`;

type ApplicationItemWrapperProps = {
  $selected: boolean;
};
export const ApplicationItemWrapper = styled.li<ApplicationItemWrapperProps>`
  background-color: ${STYLES.COLORS.WHITE};
  border-radius: 8px;
  padding: 12px;
  margin-bottom: 12px;
  border: ${({ $selected }) =>
    $selected ? `2px solid ${COLORS.PRIMARY}` : "2px solid transparent"};
`;

export const ApplicationTitleWrapper = styled.div`
  display: flex;
`;

export const PageContent = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 300px) 1fr;
  background-color: ${STYLES.COLORS.SECONDARY_BACKGROUND_COLOR};
  border-left: 16px solid ${STYLES.COLORS.SECONDARY_BACKGROUND_COLOR};
  margin-left: -16px;
  margin-right: -16px;
`;

export const ApplicationTitle = styled(Text)``;

export const ApplicationBasicInfo = styled.div`
  padding-left: 12px;
`;

export const ApplicationBasicSecond = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const LabelInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  padding-top: 12px;
`;

export const IndustryWrapper = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 12px;
`;

export const ApplicationsListWrapper = styled.ul`
  height: calc(100vh - 50px - 38px - 16px);
  overflow-y: auto;
  padding: 16px;
  padding-left: 0;
  box-sizing: border-box;
`;

export const FullPageContent = styled.div`
  height: calc(100vh - 50px - 38px - 16px);
  padding: 16px;
  overflow-y: auto;
  padding-left: 0;
  box-sizing: border-box;
  background-color: ${STYLES.COLORS.SECONDARY_BACKGROUND_COLOR};
  padding: 12px;
  margin-left: -16px;
  margin-right: -16px;
`;

export const ApprovedApplicationsListWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
`;

export const ApprovedApplicationItemWrapper = styled.div`
  background-color: ${STYLES.COLORS.WHITE};
  border-radius: 8px;
  padding: 12px;
  margin-bottom: 12px;
`;

export const ApplicantProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${STYLES.COLORS.WHITE};
  border-radius: 8px;
  height: calc(100vh - 50px - 38px - 16px - 32px);
  overflow-y: auto;
  padding: 16px;
  margin: 16px;
  margin-left: 0;
  box-sizing: border-box;
`;

export const ApplicantProfileContent = styled.div`
  flex: 1;
  padding-bottom: 70px;
`;

export const ProfileInfoWrapper = styled.div`
  display: grid;
  grid-template-columns: 150px 1fr;
`;

export const RightSection = styled.div`
  margin-left: 12px;
`;

export const BasicInfo = styled.div`
  border-bottom: 1px solid ${STYLES.COLORS.BORDER_COLOR};
  padding-bottom: 12px;
  margin-bottom: 12px;
`;

export const BasicTitleInfo = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 5px;
`;

export const NameInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const AppliedTimeWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
`;

export const SecondaryInfoWrapper = styled.li`
  position: relative;
  &:not(:last-child) {
    padding-right: 12px;
    &:after {
      content: "";
      position: absolute;
      display: block;
      width: 1px;
      height: 50%;
      background-color: ${STYLES.COLORS.BORDER_COLOR};
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
  }
`;

export const SecondaryInfoList = styled.ul`
  display: flex;
  gap: 12px;
  list-style: none;
  align-items: center;
`;

export const InsightsWrapper = styled.div`
  background-color: ${STYLES.COLORS.GREY50};
  border-radius: 8px;
  padding: 12px;
  margin-top: 12px;
  flex: 1;
`;

export const InsightsTitle = styled(Text)`
  padding-bottom: 12px;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 3px;
  color: ${COLORS.GREY500};
`;

export const InsightInfoWrapper = styled.li`
  padding: 12px 0;
  flex: 1;
  &:not(:last-child) {
    border-bottom: 1px solid ${STYLES.COLORS.BORDER_COLOR};
  }
`;

export const InsightsContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 5fr;
  gap: 12px;
`;

export const InsightInfoContent = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

export const InsightInfoList = styled.ul`
  background-color: ${STYLES.COLORS.WHITE};
  border-radius: 8px;
  list-style: none;
  padding: 0 12px;
`;

export const InsightDetailWrapper = styled.div`
  background-color: ${STYLES.COLORS.WHITE};
  border-radius: 8px;
  padding: 12px;
`;

export const ChartsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
  gap: 12px;
`;

export const InsightChartWrapper = styled.div`
  background-color: ${STYLES.COLORS.GREY50};
  padding: 12px;
  border-radius: 6px;
  flex: 1;
`;

export const DonutChartWrapper = styled.div`
  margin-top: 12px;
  display: flex;
  align-items: center;
`;

export const ChartLegendWrapper = styled.div`
  padding-left: 40px;
`;

export const LegendTitle = styled(Text)`
  padding-left: 8px;
  font-size: 13px;
`;

export const ChartLegend = styled.div<{ $color: string }>`
  display: grid;
  column-gap: 16px;
  grid-template-columns: repeat(2, 1fr);
  /* display: flex;
  align-items: center;
  gap: 4px; */
  position: relative;
  width: 100%;
  &:before {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    position: absolute;
    background-color: ${({ $color }) => $color};
    border-radius: 50%;
    top: 50%;
    transform: translate(-100%, -50%);
  }
`;

export const ActionButtonsWrapper = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: flex-end;
  padding: 12px;
  border-top: 1px solid ${STYLES.COLORS.BORDER_COLOR};
`;

export const RejectReasonWrapper = styled.div`
  padding-top: 32px;
`;

export const ApplicationSecondaryInfo = styled.div`
  padding-top: 12px;
  display: flex;
  gap: 12px;
  align-items: center;
`;
