import styled from "styled-components";
import { STYLES } from "../../Constants";

export const BrandCampaignsWrapper = styled.section`
  padding: 16px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  padding-bottom: 16px;
  justify-content: space-between;
`;

export const ActionWrapper = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: space-between;
`;

export const DropdownContent = styled.a`
  padding: 8px;
  padding-left: 0;
  padding-right: 0;
  min-width: 16px;
  color: ${STYLES.COLORS.PRIMARY_TEXT};
`;
