import * as types from "./BrandApplications.types";
import * as CONSTANTS from "./BrandApplications.constants";

function formatApplicationInsight(insight: types.ApplicantListInsight) {
  switch (insight.key) {
    case CONSTANTS.INSIGHT_TYPES.ENGAGEMENT_RATE:
      return `> ${insight.value}%`;
    default:
      return insight.value;
  }
}

export function transformApplicationList(
  response: types.ApplicationListRawResponse
) {
  if (response.result.applications === null) {
    return {
      message: response.message,
      campaign: response.result.campaign,
      applications: null,
    };
  } else {
    return {
      campaign: response.result.campaign,
      applications: response.result.applications.map((application) => ({
        id: application.id,
        insights: application.insights.map((insight) => ({
          key: insight.key,
          label: insight.label,
          value: formatApplicationInsight(insight),
          type: insight.type,
        })),
        partner: application.partner,
        partner_id: application.partner_id,
        tag: application.tag,
        rejection_reasons: application.rejection_reasons,
      })),
    };
  }
}
