import { ICONS } from "../../Assets/Icons/Icons";

export const MENU_ITEMS = [
  {
    label: "Dashboard",
    key: "home",
    Icon: ICONS.home,
    path: "/brand/home",
  },
  {
    label: "My Campaigns",
    key: "notifications",
    Icon: ICONS.megaphone,
    path: "/brand/campaigns",
  },
  {
    label: "Logout",
    key: "logout",
    Icon: ICONS.logout,
  },
];
