export const API_ENDPOINTS = {
  MY_CAMPAIGNS: "/client/brand/my-campaigns",
  CAMPAIGN: "/campaign",
} as const;

export const COLUMNS = [
  {
    title: "Title",
    dataIndex: "title",
    key: "title",
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
  },
  {
    title: "Budget",
    dataIndex: "budget",
    key: "budget",
  },
  {
    title: "Goals",
    dataIndex: "goals",
    key: "goals",
  },
  {
    title: "Launch Date",
    dataIndex: "launchDate",
    key: "launchDate",
  },

  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    key: "actions",
  },
] as const;
