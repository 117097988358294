import React from "react";
import { STYLES } from "../../Constants"; // Assuming you have constants for styles
import { formatNumberWithCommas } from "../../Utilities/Number/number.utlities";

interface CustomTooltipProps {
  active: boolean;
  payload: { name: string; value: number; count: number } | null;
  position: { top: number; left: number };
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({
  active,
  payload,
  position,
}) => {
  if (!active || !payload) return null;

  return (
    <div
      style={{
        position: "absolute",
        top: position.top,
        left: position.left,
        backgroundColor: STYLES.COLORS.GREY900,
        padding: "10px 12px",
        borderRadius: "4px",
        color: STYLES.COLORS.WHITE,
        fontSize: "12px",
        fontWeight: "700",
        lineHeight: "16px",
        letterSpacing: "0.04px",
        textAlign: "center",
        minWidth: "104px",
        pointerEvents: "none", // Prevents tooltip from interfering with hover
      }}
    >
      <p>{payload.name}</p>
      <p>{`${payload.value}% (${formatNumberWithCommas(payload.count)})`}</p>
    </div>
  );
};

export default CustomTooltip;
