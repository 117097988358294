import styled from "styled-components";
import { Tabs } from "antd";
import { COLORS } from "../../Constants/styles.constants";

export const StyledTabs = styled(Tabs)`
  &&& {
    .ant-tabs-nav {
      margin-bottom: 0;
      height: 50px;
      display: flex;
      align-items: center;
    }

    .ant-tabs-tab {
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
    }

    .ant-tabs-tab-active .ant-tabs-tab-btn {
      color: ${COLORS.PRIMARY};
      font-weight: 600;
    }

    .ant-tabs-tab-btn {
      transition: color 0.3s; // Smooth color transition for hover/active states
    }
  }
`;
