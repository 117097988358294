import { AppText } from "./Text.styles";
import * as types from "./Text.types";

export default function Text({
  color = "PRIMARY_TEXT",
  fontSize = "MEDIUM",
  fontWeight = "REGULAR",
  lineHeight = "1.5",
  textAlign = "LEFT",
  as,
  children,
  className,
  style,
}: types.TextProps) {
  return (
    <AppText
      style={style}
      $color={color}
      $fontSize={fontSize}
      $fontWeight={fontWeight}
      $lineHeight={lineHeight}
      $textAlign={textAlign}
      as={as}
      className={className}
    >
      {children}
    </AppText>
  );
}
