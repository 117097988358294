import { Link as Rlink } from "react-router-dom";
import styled, { css } from "styled-components";
import { STYLES } from "../../Constants";
import * as types from "./ButtonLikeLink.types";
import * as CONSTANTS from "./ButtonLikeLink.constants";

export const Link = styled(Rlink)<types.ButtonLikeLinkStyledProps>`
  text-decoration: none;
  color: white;
  ${({ $noPadding }) =>
    $noPadding
      ? css`
          padding: 0;
        `
      : css`
          padding: 8px 16px;
        `}
  display: block;
  border-radius: 8px;
  text-align: ${({ $align }) => $align || "center"};
  cursor: pointer;
  font-size: 14px;

  ${({ $type }) =>
    $type === CONSTANTS.BUTTON_TYPES.BUTTON
      ? css`
          background-color: ${STYLES.COLORS.PRIMARY};
          color: white;
          &:hover {
            color: white;
          }
        `
      : css`
          color: ${STYLES.COLORS.PRIMARY};
          background-color: transparent;
          &:hover {
            color: ${STYLES.COLORS.PRIMARY};
          }
        `}
`;

export const Anchor = styled.a<types.AnchorLikeLinkStyledProps>`
  text-decoration: none;
  color: white;
  ${({ $noPadding }) =>
    $noPadding
      ? css`
          padding: 0;
        `
      : css`
          padding: 8px 16px;
        `}
  display: block;
  border-radius: 8px;
  text-align: ${({ $align }) => $align || "center"};
  cursor: pointer;
  font-size: 14px;

  ${({ $type }) =>
    $type === CONSTANTS.BUTTON_TYPES.BUTTON
      ? css`
          background-color: ${STYLES.COLORS.PRIMARY};
          color: white;
          &:hover {
            color: white;
          }
        `
      : css`
          color: ${STYLES.COLORS.PRIMARY};
          background-color: transparent;
          &:hover {
            color: ${STYLES.COLORS.PRIMARY};
          }
        `}
`;
