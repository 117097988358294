import { http } from "../../Utilities";
import * as CONSTANTS from "./BrandHome.constants";
import * as types from "./BrandHome.types";

export function getDashboard() {
  return http.get<types.RawDashboardResponse>(
    CONSTANTS.API_ENDPOINTS.DASHBOARD,
    {
      new_profile: true,
    }
  );
}
