import { Form } from "antd";
import * as types from "./FormItem.types";

export default function FormItem({
  children,
  label,
  name,
  hidden,
  initialValue,
  rules,
  valuePropName,
  validationStatus,
  help,
  style,
}: types.FormItemProps) {
  return (
    <Form.Item
      style={style}
      label={label}
      name={name}
      hidden={hidden}
      initialValue={initialValue}
      rules={rules}
      valuePropName={valuePropName}
      validateStatus={validationStatus}
      help={help}
    >
      {children}
    </Form.Item>
  );
}
