import styled from "styled-components";
import { Text } from "../Components";

export const AuthLayoutWrapper = styled.main`
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100vh;
`;

export const LayoutLeft = styled.section`
  background: linear-gradient(200deg, #fbfaff, #d7d0ff);

  padding: 56px;
`;

export const LogoImage = styled.img`
  width: 120px;
`;

export const LayoutRight = styled.section`
  padding: 24px;
`;

export const LayoutHeroImage = styled.img`
  width: 240px;
  height: 240px;
  object-fit: contain;
  border-radius: 16px;
  transform: rotate(-20deg);
  margin-top: 100px;
  margin-bottom: 100px;
  margin-left: 48px;
`;

export const LayoutHelperText = styled(Text)`
  padding-top: 16px;
`;
