import styled from "styled-components";
import { COLORS } from "../../Constants/styles.constants";
import { STYLES } from "../../Constants";

export const BrandHomePageCont = styled.div`
  padding: 16px;
  background-color: ${COLORS.GREY50};
`;

export const SectionCont = styled.div`
  padding: 24px;
  background-color: ${COLORS.WHITE};
  border-radius: 12px;
  margin-bottom: 18px;
  overflow: hidden;
`;

export const SectionHeading = styled.h2`
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 4px;
`;

export const SectionDescription = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${COLORS.GREY500};
  margin-bottom: 24px;
`;

export const PendingActionsCont = styled.div`
  display: flex;
  gap: 24px;
  overflow-x: auto;
`;

export const PendingActionCardCont = styled.div`
  background-color: ${COLORS.GREY50};
  padding: 12px;
  border-radius: 8px;
  min-width: 246px;
`;

export const ActionIconCont = styled.div`
  height: 49px;
  width: 49px;
  border-radius: 4px;
  background-color: ${COLORS.WHITE};
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ActionHeading = styled.h4`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 4px;
`;

export const ActionDescription = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${COLORS.GREY500};
  margin-bottom: 12px;
`;

export const BannerCont = styled.div`
  height: 329px;
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  background: linear-gradient(-90deg, #e8e4ff 0%, transparent 100%);
  /* background-color: #e8e4ff; */
  margin-bottom: 18px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: right;
    background-repeat: no-repeat;
  }
`;

export const BannerGradient = styled.div`
  position: absolute;
  background: linear-gradient(90deg, #000000 1.54%, rgba(0, 0, 0, 0) 100%);
  z-index: 1;
  left: 15%;
  right: 20%;
  top: 0;
  bottom: 0;
`;

export const BannerContent = styled.div`
  position: absolute;
  z-index: 10;
  top: 50%;
  transform: translateY(-50%);
  width: 493px;
  padding-left: 52px;
`;

export const BannerHeading = styled.h2`
  font-weight: 700;
  font-size: 35px;
  line-height: 40px;
  letter-spacing: -0.16px;
  color: #000;
  margin-bottom: 16px;
`;

export const BannerDescription = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #000;
  margin-bottom: 16px;
`;

export const DemoCont = styled(PendingActionsCont)``;

export const DemoCardCont = styled(PendingActionCardCont)`
  cursor: pointer;
  max-width: 240px;
`;

export const DemoThumbnailCont = styled.div`
  width: 213px;
  height: 140px;
  background-color: #e8e5ff;
  border: 1px solid ${COLORS.GREY50};
  margin-bottom: 12px;
  box-shadow: 4px 4px 30px 0px #0000001a;
  border-radius: 4px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const DemoDescription = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`;
