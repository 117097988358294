import { useMutation, useQuery } from "@tanstack/react-query";
import { Button, ButtonLikeLink, Form, Text } from "../../Components";
import uploadError from "../../Assets/Images/upload_error.png";
import {
  CampaignDetailsForm,
  TargetAudienceForm,
} from "./CreateCampaign.HelperComponents";
import {
  CreateCampaignWrapper,
  FormActionsWrapper,
  TempBanner,
} from "./CreateCampaign.styles";
import createCampaignBanner from "../../Assets/Images/create_campaign_banner.png";
import * as services from "./CreateCampaign.services";
import * as utilities from "./CreateCampaign.utilities";
import * as types from "./CreateCampaign.types";
import { useNavigate } from "react-router-dom";
import Fallback from "../../Components/Fallback";
import { Form as AntdForm } from "antd";
import { useState } from "react";

export default function CreateCampaign() {
  const navigate = useNavigate();
  const [form] = AntdForm.useForm();
  const [showFallback, setShowFallback] = useState(false);

  const { data } = useQuery({
    queryKey: ["campaignMeta"],
    queryFn: services.getCampaignMeta,
    select: utilities.transformCampaignMeta,
  });

  const {
    mutateAsync: createBrandCampaignAsync,
    isPending: isCreateBrandCampaignLoading,
  } = useMutation({
    mutationFn: services.postCreateCampaign,
  });

  async function handleSubmitCreateCampaign(
    formData: types.CreateCampaignFormData
  ) {
    if (!data) {
      return;
    }
    const transformedData = utilities.transformCreateCampaignFormData(
      formData,
      data.rawResponse
    );
    const res = await createBrandCampaignAsync(transformedData);
    if (!res.success && res.error?.status_code === 500) {
      setShowFallback(true);
      return;
    }
    navigate("/");
  }

  const handleCtaOnClick = async () => {
    try {
      const formData = await form.validateFields();
      await handleSubmitCreateCampaign(formData);
    } catch (error) {
      console.error("Form validation failed:", error);
    }
  };

  return (
    <CreateCampaignWrapper>
      {showFallback ? (
        <Fallback
          imgSrc={uploadError}
          ctaOnClick={handleCtaOnClick}
          ctaTitle="Try again"
          loading={false}
          heading="Something is off from our side"
          subText="We’re sorry for the inconvenience, please try again"
        />
      ) : null}

      <Text fontSize="LARGE" fontWeight="SEMI_BOLD" lineHeight="1.2">
        Campaign form
      </Text>
      <Text color="SECONDARY_TEXT" fontSize="SMALL">
        Request a call back to create a campaign.
      </Text>
      <TempBanner>
        <img src={createCampaignBanner} alt="create campaign banner" />
      </TempBanner>
      <Text fontSize="LARGE" fontWeight="SEMI_BOLD" lineHeight="1.2">
        Hi, help us with the following information
      </Text>
      <Text color="SECONDARY_TEXT" fontSize="SMALL">
        Our expert will assist you in launching your campaign.
      </Text>
      <Form onSubmit={handleSubmitCreateCampaign} form={form}>
        <CampaignDetailsForm
          goals={data?.goals || []}
          types={data?.types || []}
        />
        <TargetAudienceForm
          ages={data?.ages || []}
          genders={data?.genders || []}
          locations={data?.locations || []}
        />
        <FormActionsWrapper>
          <ButtonLikeLink text="Cancel" to="/" type="LINK" />
          <Button
            text="Confirm call back"
            type="primary"
            size="large"
            htmlType="submit"
            loading={isCreateBrandCampaignLoading}
          />
        </FormActionsWrapper>
      </Form>
    </CreateCampaignWrapper>
  );
}
