import styled from "styled-components";
import { COLORS } from "../../Constants/styles.constants";

export const BrandWrapper = styled.div`
  --header-height: 48px;
  display: flex;
  min-height: 100vh;
`;

export const BrandNavigation = styled.nav`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid #c8ced7;
  padding-top: 16px;
  padding-bottom: 16px;
  height: 100vh;
  position: fixed;
  z-index: 100;
  background-color: ${COLORS.WHITE};
`;

export const BrandLogo = styled.img`
  width: 32px;
`;

export const BrandContent = styled.div<{ navWidth: number }>`
  width: calc(100% - ${(props) => props.navWidth}px);
  margin-left: ${(props) => props.navWidth}px;
`;
