import React from "react";
import { Dropdown as AntDropdown, Menu, MenuProps } from "antd";
import { DropdownProps } from "./Dropdown.types";

function Dropdown({ items, children, onClick, getItemStyle }: DropdownProps) {
  const menu = (
    <Menu
      onClick={onClick}
      items={items.map((item) => {
        if (item && "label" in item) {
          return {
            ...item,
            label: (
              <span
                style={getItemStyle ? getItemStyle(item.label as string) : {}}
              >
                {item.label}
              </span>
            ),
          };
        }
        return item;
      })}
    />
  );

  return <AntDropdown overlay={menu}>{children}</AntDropdown>;
}

export default Dropdown;
