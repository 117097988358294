import styled from "styled-components";

export const ResetRight = styled.section`
  padding: 32px;
  padding-top: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ResetRightContent = styled.div`
  width: 364px;
`;

export const FormWrapper = styled.div`
  padding-top: 32px;
`;
