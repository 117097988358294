import { useSearchParams } from "react-router-dom";
import {
  Button,
  ButtonLikeLink,
  Form,
  FormItem,
  Input,
  Text,
} from "../../Components";
import {
  FormWrapper,
  ResetRight,
  ResetRightContent,
} from "./RequestForgotPassword.styles";
import * as types from "./RequestForgotPassword.types";
import { useMutation } from "@tanstack/react-query";
import * as services from "./RequestForgotPassword.services";
import EmailIllustration from "../../Assets/Images/email_illustrations.png";
import {
  VerifyEmailActions,
  VerifyEmailContainer,
  VerifyEmailContent,
  VerifyEmailImage,
} from "../VerifyEmail/VerifyEmail.styles";

export default function RequestForgotPassword() {
  const [searchParams] = useSearchParams();

  const resetPasswordMutation = useMutation({
    mutationFn: services.postResetPassword,
  });

  function handleForgotPassword(formData: types.ForgotPasswordFormData) {
    resetPasswordMutation.mutate(formData);
  }

  return (
    <ResetRight>
      <ResetRightContent>
        {resetPasswordMutation.isSuccess &&
        resetPasswordMutation.data.success ? (
          <VerifyEmailContainer>
            <VerifyEmailContent>
              <VerifyEmailImage src={EmailIllustration} alt="Email" />
              <Text
                style={{
                  fontSize: "24px",
                  lineHeight: "30px",
                }}
                lineHeight="1"
                fontSize="LARGE"
                fontWeight="SEMI_BOLD"
                textAlign="CENTER"
              >
                Reset password initiated
              </Text>
              <Text
                style={{
                  fontSize: "14px",
                  lineHeight: "20px",
                }}
                lineHeight="1"
                color="SECONDARY_TEXT"
                textAlign="CENTER"
                fontSize="X_SMALL"
              >
                A password reset link is sent to your email address
              </Text>
              <VerifyEmailActions>
                <ButtonLikeLink to="/login" text="Go to Login" />
              </VerifyEmailActions>
            </VerifyEmailContent>
          </VerifyEmailContainer>
        ) : (
          <FormWrapper>
            <Text
              style={{
                fontSize: "24px",
                lineHeight: "30px",
                marginBottom: "6px",
              }}
              fontWeight="SEMI_BOLD"
              fontSize="XXX_LARGE"
              as="h1"
              lineHeight="1"
              textAlign="CENTER"
            >
              Forgot Password
            </Text>
            <Text
              style={{
                lineHeight: "20px",
                fontSize: "14px",
                marginBottom: "48px",
              }}
              color="SECONDARY_TEXT"
              textAlign="CENTER"
            >
              A password reset link will be sent to your email address
            </Text>
            <Form<types.ForgotPasswordFormData> onSubmit={handleForgotPassword}>
              <FormItem
                label={<Text>Enter Email</Text>}
                name="email"
                initialValue={searchParams.get("email") || ""}
                rules={[
                  {
                    type: "email",
                    message: "Please enter a correct email address",
                  },
                  {
                    required: true,
                    message: "Please input your E-mail!",
                  },
                ]}
              >
                <Input placeholder="eg. xyz@gmail.com" />
              </FormItem>
              <FormItem>
                <Button
                  text="Send Email"
                  fullWidth
                  type="primary"
                  size="large"
                  htmlType="submit"
                  loading={resetPasswordMutation.isPending}
                />
              </FormItem>
            </Form>
          </FormWrapper>
        )}
      </ResetRightContent>
    </ResetRight>
  );
}
