import { Modal as AntdModal } from "antd";
import * as types from "./Modal.types";

function Modal({
  open,
  title,
  children,
  onCancel,
  onOkay,
  destroyOnClose,
  okayButtonProps,
  okayText,
  width,
  footer,
  centered,
}: types.ModalProps) {
  return (
    <AntdModal
      centered={centered}
      open={open}
      title={title}
      onCancel={onCancel}
      onOk={onOkay}
      destroyOnClose={destroyOnClose}
      okText={okayText}
      okButtonProps={okayButtonProps}
      width={width}
      footer={footer}
    >
      {children}
    </AntdModal>
  );
}

export default Modal;
