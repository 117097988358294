import { http } from "../../Utilities";
import { ValueOf } from "../../Utilities/Types";
import { url } from "../../Utilities/Url";
import * as CONSTANTS from "./BrandApplications.constants";
import * as types from "./BrandApplications.types";

export function getCampaignApplications(campaignId: string, status: string) {
  return http.get<types.ApplicationListRawResponse>(
    CONSTANTS.API_ENDPOINTS.APPLICATIONS,
    { campaign_ids: campaignId, status }
  );
}

export function getApplicationData(applicationId: number) {
  return http.get<types.ApplicantRawResponse>(
    url.replacePathParams(CONSTANTS.API_ENDPOINTS.GET_APPLICATION, {
      applicationId,
    })
  );
}

export function updateApplicationStatus({
  applicationId,
  status,
  rejectReason,
}: {
  applicationId: number;
  status: ValueOf<typeof CONSTANTS.APPLICATION_STATUS>;
  rejectReason?: types.RejectReason;
}) {
  return http.put<types.ApplicantRawResponse>(
    CONSTANTS.API_ENDPOINTS.APPLICATION,
    {
      ids: [applicationId],
      updates: { status, rejection_reason: rejectReason },
    }
  );
}
