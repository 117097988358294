import React, { useEffect, useState } from "react";
import { ICONS } from "../../Assets/Icons/Icons";
import { Button } from "../../Components";
import { COLORS } from "../../Constants/styles.constants";
import {
  Footer,
  Header,
  MainSection,
  MainSectionContent,
  MainSectionHeading,
  PageHeading,
  SuccessCont,
  ThumbnailCont,
  TutCardBGContent,
  TutCardCont,
  TutCards,
  TutDescription,
  TutLinkCard,
  TutName,
  TutNumber,
  TutTitle,
} from "./FBConnect.styles";
import { Modal } from "antd";
import { useMutation, useQuery } from "@tanstack/react-query";
import * as services from "./FBConnect.services";
import { ClipLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import LogoImage from "../../Assets/Images/logo.png";
import instagram from "../../Assets/Images/instagram.png";
import FullPageLoader from "../../Components/FullPageLoader/FullPageLoader";
import VideoPlayerScreen from "../VideoPlayerScreen/VideoPlayerScreen";

// Define the Facebook SDK window augmentation
declare global {
  interface Window {
    FB?: {
      login(
        callback: (response: any) => void,
        options: { scope: string }
      ): void;
      getLoginStatus(callback: (response: any) => void): void;
    };
  }
}

const FBConnect: React.FC = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");

  const { mutateAsync: connectSocial, isPending } = useMutation({
    mutationFn: (data: { token: string }) =>
      services.postCreateCampaign(data, { token: data.token }),
  });

  const handleConnectSocial = async (token: string) => {
    const res = await connectSocial({ token });
    if (
      !res.success &&
      res.error?.status_code &&
      res.error?.status_code >= 400 &&
      res.error?.status_code <= 500
    ) {
      setError(true);
      return;
    }
    if (res.success) {
      setShowModal(false);
      setSuccess(true);
      return;
    }
  };

  const { data, isPending: isMetaPending } = useQuery({
    queryKey: ["metaFbConnect"],
    queryFn: () => services.getMetaFBConnect(),
  });

  const meta = data?.result;

  const logInToFB = () => {
    if (window.FB) {
      window.FB.login(
        (response: any) => {
          const accessToken = response.authResponse?.accessToken || "";
          if (accessToken) {
            setShowModal(true);
            console.log(accessToken);
            handleConnectSocial(accessToken);
          } else {
            console.error("Failed to get access token");
          }
        },
        {
          scope: meta?.permissions.join(",") || "",
        }
      );
    } else {
      console.error("Facebook SDK not loaded");
    }
  };

  useEffect(() => {
    if (window.FB) {
      window.FB.getLoginStatus((response: any) => {
        console.log(response.authResponse?.accessToken || "");
      });
    }
  }, []);

  const TUTS = meta?.steps.map((curr, i) => {
    return {
      step: i + 1,
      tut_label: curr.title,
      tut_name: curr.card?.title,
      tut_description: curr.card?.description,
      thumbnail_url: curr.card?.thumbnail,
      on_click_url: curr.card?.url,
    };
  });

  if (success) {
    return <SuccessUI />;
  }
  if (isMetaPending) {
    return <FullPageLoader />;
  } else {
    return (
      <div
        style={{
          position: "relative",
          height: "100vh",
          overflow: "hidden",
          backgroundColor: COLORS.GREY50,
        }}
      >
        <Header>
          <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
            <img
              src={ICONS.arrowLeft}
              alt="Back"
              onClick={() => {
                navigate("/brand/home");
              }}
            />
            <PageHeading>{meta?.page_title}</PageHeading>
          </div>
          <Button
            style={{
              height: "40px",
              display: "flex",
              alignItems: "center",
              fontWeight: "600",
            }}
            onClick={() => {
              window.open("https://ryme.ai/contact-us/", "_blank");
            }}
            text="Support"
          />
        </Header>
        <MainSection>
          <VideoPlayerScreen
            open={Boolean(videoUrl)}
            onCancel={() => {
              setVideoUrl("");
            }}
            url={videoUrl}
          />
          <MainSectionContent>
            <MainSectionHeading>
              In order to connect your Instagram please ensure that <br />
              following steps are followed
            </MainSectionHeading>
            <TutCards>
              {TUTS &&
                TUTS.map((curr) => (
                  <TutCardCont key={curr.step}>
                    <TutCardBGContent>
                      <TutNumber>
                        <p>{curr.step}</p>
                      </TutNumber>
                      <TutTitle>{curr.tut_label}</TutTitle>
                    </TutCardBGContent>
                    <TutLinkCard
                      onClick={() => {
                        if (curr.on_click_url) {
                          setVideoUrl(curr.on_click_url);
                        }
                      }}
                    >
                      <ThumbnailCont>
                        <img
                          src={curr.thumbnail_url}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "contain",
                          }}
                        />
                      </ThumbnailCont>
                      <TutName>{curr.tut_name}</TutName>
                      <TutDescription>{curr.tut_description}</TutDescription>
                    </TutLinkCard>
                  </TutCardCont>
                ))}
            </TutCards>
          </MainSectionContent>
        </MainSection>
        <Footer>
          <Button
            style={{
              height: "40px",
              display: "flex",
              alignItems: "center",
              fontWeight: "600",
            }}
            text="Connect now"
            type="primary"
            onClick={logInToFB}
          />
        </Footer>
        <Modal
          open={showModal}
          footer={null}
          centered
          onCancel={() => setShowModal(false)}
        >
          {isPending ? (
            <div
              style={{
                height: "200px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ClipLoader color={COLORS.PRIMARY} />
            </div>
          ) : error ? (
            <ErrorUI
              tryAgain={() => {
                setShowModal(false);
                logInToFB();
              }}
            />
          ) : null}
        </Modal>
      </div>
    );
  }
};

type ErrorUIProps = {
  tryAgain: () => void;
};

const ErrorUI: React.FC<ErrorUIProps> = ({ tryAgain }) => {
  return (
    <>
      <h3
        style={{
          fontWeight: "600",
          fontSize: "20px",
          lineHeight: "32px",
          marginBottom: "4px",
        }}
      >
        There was an error
      </h3>
      <p
        style={{
          fontWeight: "400",
          fontSize: "14px",
          lineHeight: "22px",
          color: COLORS.GREY500,
          marginBottom: "32px",
        }}
      >
        We could not connect with your Instagram account, please make sure that
        your Instagram account linked to Facebook and is set to Professional
      </p>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          gap: "12px",
        }}
      >
        <Button
          style={{
            height: "40px",
            display: "flex",
            alignItems: "center",
            fontWeight: "600",
          }}
          text="Contact support"
          onClick={() => {
            window.open("https://ryme.ai/contact-us/", "_blank");
          }}
        />
        <Button
          style={{
            height: "40px",
            display: "flex",
            alignItems: "center",
            fontWeight: "600",
          }}
          type="primary"
          text="Try again"
          onClick={tryAgain}
        />
      </div>
    </>
  );
};

export default FBConnect;

export const SuccessUI = () => {
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState(3);

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    if (countdown === 0) {
      navigate("/brand/home");
    }

    return () => clearInterval(timer);
  }, [countdown]);

  return (
    <SuccessCont>
      <div
        style={{
          width: "400px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div style={{ display: "flex", marginBottom: "28px" }}>
          <div
            style={{
              backgroundColor: COLORS.PURPLE50,
              width: "78px",
              height: "78px",
              borderRadius: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginRight: "-20px",
            }}
          >
            <img
              src={instagram}
              style={{ width: "54px", borderRadius: "100%" }}
            />
          </div>
          <div
            style={{
              backgroundColor: COLORS.PURPLE50,
              width: "78px",
              height: "78px",
              borderRadius: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{ backgroundColor: COLORS.PRIMARY, borderRadius: "100%" }}
            >
              <img
                src={LogoImage}
                alt="Logo"
                style={{ width: "54px", borderRadius: "100%" }}
              />
            </div>
          </div>
        </div>
        <h1
          style={{
            fontSize: "24px",
            fontWeight: "600",
            lineHeight: "30px",
            marginBottom: "4px",
            textAlign: "center",
          }}
        >
          Instagram connected successfully
        </h1>
        <p
          style={{
            marginBottom: "28px",
            fontWeight: "500",
            color: COLORS.GREY500,
          }}
        >
          Returning to dashboard in {countdown}
        </p>
        <Button
          style={{
            height: "40px",
            display: "flex",
            alignItems: "center",
            fontWeight: "600",
            width: "100%",
            justifyContent: "center",
          }}
          text="Go to Dashboard"
          onClick={() => {
            navigate("/brand/home");
          }}
        />
      </div>
    </SuccessCont>
  );
};
