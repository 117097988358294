import React, { useEffect, useRef } from "react";
import Hls from "hls.js";
import { Modal } from "antd";
import FullPageLoader from "../../Components/FullPageLoader/FullPageLoader";

function VideoPlayerScreen({
  url,
  open,
  onCancel,
}: {
  url: string;
  open: boolean;
  onCancel: () => void;
}) {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (!url || !videoRef.current) return;

    const video = videoRef.current;

    // Function to handle direct video playback
    const handleDirectVideo = () => {
      if (videoRef.current) {
        videoRef.current.src = url;
      }
    };

    // Function to initialize HLS
    const initializeHls = () => {
      const hls = new Hls({
        enableWorker: true,
        lowLatencyMode: true,
      });

      hls.loadSource(url);
      hls.attachMedia(video);

      hls.on(Hls.Events.ERROR, (event, data) => {
        if (data.fatal) {
          switch (data.type) {
            case Hls.ErrorTypes.NETWORK_ERROR:
              // Try to recover network error
              console.log("Fatal network error encountered, trying to recover");
              hls.startLoad();
              break;
            case Hls.ErrorTypes.MEDIA_ERROR:
              // Try to recover media error
              console.log("Fatal media error encountered, trying to recover");
              hls.recoverMediaError();
              break;
            default:
              // Cannot recover
              console.error("Fatal error, cannot recover");
              hls.destroy();
              break;
          }
        }
      });

      return hls;
    };

    let hls: Hls | null = null;

    // Check if the URL is an HLS stream
    if (url.includes(".m3u8")) {
      // Check for HLS support
      if (Hls.isSupported()) {
        hls = initializeHls();
      } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
        // For browsers that have built-in HLS support (Safari)
        handleDirectVideo();
      }
    } else {
      // For regular video formats
      handleDirectVideo();
    }

    // Cleanup
    return () => {
      if (hls) {
        hls.destroy();
      }
    };
  }, [url]);

  return (
    <Modal
      width="800px"
      styles={{
        body: {
          height: "73vh",
        },
      }}
      centered
      open={open}
      onCancel={onCancel}
      footer={null}
    >
      {!url ? (
        <FullPageLoader />
      ) : (
        <div
          style={{
            width: "100%",
            height: "100%",
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
          }}
        >
          <video
            ref={videoRef}
            controls
            style={{ width: "100%", height: "auto" }}
            playsInline
          />
        </div>
      )}
    </Modal>
  );
}

export default VideoPlayerScreen;
