import { ClipLoader } from "react-spinners";
import { COLORS } from "../../Constants/styles.constants";

const FullPageLoader = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ClipLoader color={COLORS.PRIMARY} size={"48px"} />
    </div>
  );
};

export default FullPageLoader;
