import React from "react";
import { Table as AntdTable } from "antd";
import type { ColumnsType } from "antd/es/table";
import * as types from "./Table.types";
import { COLORS } from "../../Constants/styles.constants";

interface HeaderCellProps extends React.ThHTMLAttributes<HTMLTableCellElement> {
  children?: React.ReactNode;
}

const HeaderCell: React.FC<HeaderCellProps> = ({ children, ...restProps }) => (
  <th
    {...restProps}
    style={{
      backgroundColor: COLORS.WHITE,
      color: COLORS.GREY500,
      fontSize: "12px",
      fontWeight: "600",
    }}
  >
    {children}
  </th>
);

function Table<TableRowData extends object>({
  columns,
  data,
  rowKey,
  loading,
  customColumns = [],
}: types.TableProps<TableRowData>) {
  const getCustomComponent = (columnKey: string) => {
    return customColumns.find((customCol) => customCol.key === columnKey)
      ?.component;
  };

  const getCustomWidth = (columnKey: string) => {
    return customColumns.find((customCol) => customCol.key === columnKey)
      ?.width;
  };

  const updatedColumns: ColumnsType<TableRowData> = columns.map((column) => {
    const CustomComponent = getCustomComponent(column.key as string);
    const columnWidth =
      getCustomWidth(column.key as string) || column.width || "auto";

    if (CustomComponent) {
      return {
        ...column,
        width: columnWidth,
        render: (text: any, record: TableRowData) => (
          <CustomComponent value={text} record={record} />
        ),
      };
    }

    return {
      ...column,
      width: columnWidth,
    };
  });

  return (
    <AntdTable<TableRowData>
      columns={updatedColumns}
      dataSource={data}
      rowKey={rowKey}
      loading={loading}
      pagination={false}
      components={{
        header: {
          cell: HeaderCell,
        },
      }}
    />
  );
}

export default Table;
