import { Link, Outlet, useNavigate } from "react-router-dom";
import {
  BrandContent,
  BrandLogo,
  BrandNavigation,
  BrandWrapper,
} from "./Brand.styles";
import { Avatar, Menu } from "../../Components";
import { AUTH } from "../../Constants";
import { useEffect, useRef, useState } from "react";
import { MENU_ITEMS } from "./Brand.constants";
import LogoImage from "../../Assets/Images/logo.png";

function Brand() {
  const navigate = useNavigate();
  const navRef = useRef<HTMLDivElement>(null);
  const [navWidth, setNavWidth] = useState(0);

  function handleLogout() {
    localStorage.setItem(AUTH.AUTH_TOKEN_LOCAL_STORAGE_KEY, "");
    navigate("/login");
  }

  useEffect(() => {
    const token = localStorage.getItem(AUTH.AUTH_TOKEN_LOCAL_STORAGE_KEY);
    if (!token) {
      navigate("/login");
    }
  }, [navigate]);

  useEffect(() => {
    if (navRef.current) {
      setNavWidth(navRef.current.offsetWidth);
    }
  }, []);

  function handleMenuClick(key?: number | bigint | string | undefined) {
    if (key === "logout") {
      handleLogout();
      return;
    }
    const menuItem = MENU_ITEMS.find((item) => item.key === key);
    navigate(menuItem?.path || "/");
  }

  return (
    <BrandWrapper>
      <BrandNavigation ref={navRef}>
        <BrandLogo src={LogoImage} alt="Ryme Logo" />
        <Menu
          items={MENU_ITEMS.map((item) => ({
            label: item.label,
            key: item.key,
            icon: (
              <div style={{ height: "18px", width: "18px" }}>
                <img
                  src={item.Icon}
                  alt={item.label}
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
            ),
          }))}
          onClick={(info) => handleMenuClick(info?.key)}
        />
        <Avatar alt="U" src="" size={32} />
      </BrandNavigation>
      <BrandContent navWidth={navWidth}>
        <Outlet />
      </BrandContent>
    </BrandWrapper>
  );
}

export default Brand;
