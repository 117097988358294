import {
  PieChart as RePieChart,
  Pie,
  Cell,
  Tooltip,
  TooltipProps,
} from "recharts";
import * as types from "./DonutChart.types";
import { STYLES } from "../../Constants";
import { formatNumberWithCommas } from "../../Utilities/Number/number.utlities";

const CustomTooltip = ({ active, payload }: TooltipProps<any, any>) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;

    return (
      <div
        style={{
          backgroundColor: STYLES.COLORS.GREY900,
          padding: "10px 12px",
          borderRadius: "4px",
          color: STYLES.COLORS.WHITE,
          fontSize: "12px",
          fontWeight: "700",
          lineHeight: "16px",
          letterSpacing: "0.04px",
          textAlign: "center",
          minWidth: "104px",
        }}
      >
        <p>{`${data.name}`}</p>
        <p>{`${data.value}% (${formatNumberWithCommas(data.count)})`}</p>
      </div>
    );
  }
  return null;
};

function PieChart({ chartCenterText, data }: types.DonutChartProps) {
  return (
    <RePieChart width={112} height={112}>
      <Pie
        data={data}
        innerRadius={38}
        outerRadius={56}
        dataKey="value"
        activeIndex={0}
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={entry.color} />
        ))}
      </Pie>

      {/* Custom Tooltip for better control over styles */}
      <Tooltip content={<CustomTooltip />} />

      {/* Always render chartCenterText */}
      <text
        x={112 / 2}
        y={112 / 2}
        textAnchor="middle"
        dominantBaseline="middle"
        fontSize="16"
        fontWeight="bold"
        fill={STYLES.COLORS.SECONDARY_TEXT}
      >
        {chartCenterText}
      </text>
    </RePieChart>
  );
}

export default PieChart;
