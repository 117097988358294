import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const DontAllowAuthenticated = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [authChecked, setAuthChecked] = useState(false); // To track when auth check is complete
  const navigate = useNavigate();

  useEffect(() => {
    const authToken = localStorage.getItem("authToken");
    if (authToken) {
      // Redirect to home if authToken is found
      navigate("/brand/home");
    } else {
      // Allow rendering the children only after auth check is done
      setAuthChecked(true);
    }
  }, [navigate]);

  // Render nothing until the auth check is done
  if (!authChecked) {
    return null;
  }

  // Once auth is checked, render children
  return <>{children}</>;
};

export default DontAllowAuthenticated;
