import styled from "styled-components";
import { Text } from "../../Components";

export const CreateCampaignWrapper = styled.main`
  max-width: 800px;
  margin: 0 auto;
  padding: 16px;
  padding-bottom: 80px;
`;

export const TempBanner = styled.div`
  height: 150px;
  margin-top: 16px;
  margin-bottom: 16px;
  background-color: #f6f7f8;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  overflow: hidden;
`;

export const TitleText = styled(Text)`
  margin-top: 16px;
  margin-bottom: 16px;
  padding-bottom: 8px;
  border-bottom: 1px solid #e0e0e0;
`;

export const FormActionsWrapper = styled.div`
  position: fixed;
  width: 100vw;
  bottom: 0;
  left: 0;
  height: 64px;
  padding: 16px;
  background-color: white;
  border-top: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
`;
