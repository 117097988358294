import { http } from "../../Utilities";
import * as CONSTANTS from "./FBConnect.constants";
import * as types from "./FBConnect.types";

export function postCreateCampaign(
  data: any,
  queryParams?: Record<string, any>
) {
  const queryString = queryParams
    ? `?${new URLSearchParams(queryParams).toString()}`
    : "";

  return http.post<types.SocialConnectResponse>(
    `${CONSTANTS.API_ENDPOINTS.SOCIAL_CONNECT}${queryString}`,
    data
  );
}

export function getMetaFBConnect() {
  return http.get<types.MetaRawResponse>(
    CONSTANTS.API_ENDPOINTS.META_FB_CONNECT
  );
}
